<template>
  <HeaderDialog
    :title="$t('imageUploader.addImage')"
    color="primarySuite"
    :show="show"
    :loading="uploadingImageLoading"
    width="700"
    :disabledAction="!imageUrlByType || !!photoLinkError"
    showCancel
    :actionText="$t('action.confirm')"
    :closeText="$t('action.cancel')"
    button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
    @close="closeImageUploadDialog"
    @action="uploadImage"
  >
    <template v-slot:body>
      <v-row class="flex-column justify-center">
        <div class="pb-4 pt-8 mx-4 d-flex">
          <v-img
            v-if="!imageUrlByType"
            :src="getPreviewTagImage"
            contain
            max-width="260"
          />
          <v-img v-else :src="imageUrlByType" contain max-width="260" />

          <div class="ml-4" v-if="hasAcceptedDriveScopes">
            <p>
              {{
                `${$t("imageUploader.selectImageUrl")} ${$t(
                  "common.or"
                ).toLocaleLowerCase()} ${$t("imageUploader.selectImageUpload")}`
              }}
            </p>

            <v-radio-group class="mt-0" v-model="radioGroup" hide-details>
              <div
                class="d-flex align-items-center mb-2"
                style="display: flex; align-items: center; margin"
              >
                <v-radio
                  class="mb-0 mr-1"
                  :label="$t('common.insertLink')"
                  value="link"
                >
                </v-radio>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="info"
                      small
                      v-bind="attrs"
                      v-on="on"
                      v-text="'mdi-help-circle-outline'"
                    />
                  </template>
                  <span>{{ $t("userInformations.photoTooltipText") }}</span>
                </v-tooltip>
              </div>
              <v-radio
                v-if="!isCompanyMicrosoft"
                :label="$t('onboardingNewUser.uploadImage')"
                value="drive"
              ></v-radio>
            </v-radio-group>
            <div class="text-body-2 ml-1 mt-3" v-if="imageSizeAlert">
              <span class="red--text">{{ $t("common.attention") }}:</span>
              <span>
                {{
                  $t("imageUploader.warningImageSize", {
                    size: imgSize,
                  })
                }}
              </span>
            </div>
          </div>
        </div>

        <div
          v-if="radioGroup == 'drive' && hasAcceptedDriveScopes"
          class="mt-2 mx-4"
        >
          <v-file-input
            ref="fileupload"
            accept="image/*"
            :label="$t('imageUploader.selectImage')"
            :hint="$t('imageUploader.selectImageHint')"
            persistent-hint
            @change="handleFileUpload"
          ></v-file-input>
        </div>
        <div v-if="radioGroup == 'link'" class="mt-4 mx-4">
          <v-text-field
            prepend-icon="mdi-link"
            :label="$t('imageUploader.imageUrl')"
            clearable
            v-model="imageURLPreview.link"
            @input="updateURL"
            color="secondary"
            @blur="checkPhotoLink"
            :error-messages="photoLinkError"
          >
          </v-text-field>
        </div>
      </v-row>
    </template>
  </HeaderDialog>
</template>

<script>
import { changeDriveURL, isPublicLink } from "@/helpers/services/images";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import { mapGetters } from "vuex";

export default {
  name: "ImageUploader",

  props: {
    user: { type: Object, required: true, default: () => {} },
    userToken: { type: String, required: false, default: "" },
    token: { type: String, required: false, default: "" },
    show: { type: Boolean, default: false },
    selectedTagImageURL: { type: String, default: "" },
  },

  data: () => ({
    file: null,
    uploadingImageLoading: false,
    imageURLPreview: {
      link: "",
      drive: "",
    },
    radioGroup: "link",
    imageSizeAlert: false,
    imgSize: 0,
    photoLinkError: "",
  }),

  methods: {
    changeDriveURL,

    updateURL() {
      this.imageURLPreview.link = changeDriveURL(this.imageURLPreview.link);
    },

    async checkPhotoLink() {
      const url = this.imageURLPreview.link;
      this.photoLinkError = "";
      if (!url) {
        return;
      }

      const isPublic = await isPublicLink(url);
      if (!isPublic) {
        this.photoLinkError = this.$t("errorMessages.photoLink");
        return;
      }
    },

    closeImageUploadDialog() {
      if (this.imageURLPreview.drive) {
        this.$refs.fileupload.reset();
      }

      this.imageURLPreview = {
        link: "",
        drive: "",
      };
      this.file = null;
      this.imageSizeAlert = false;
      this.photoLinkError = "";
      this.$emit("close");
    },

    handleFileUpload(imageFile) {
      if (imageFile) {
        this.imageURLPreview.drive = URL.createObjectURL(imageFile);
        this.checkImageSize(imageFile.size);
        this.file = imageFile;
      } else {
        this.imageURLPreview.drive = "";
        this.file = null;
      }
    },

    async checkImageSize(size) {
      const sizeInBytes = size;
      const megasInBytes = 1048576;
      const sizeInMegas = sizeInBytes / megasInBytes;
      const maxImageSizeInMegas = 2;
      if (sizeInMegas > maxImageSizeInMegas) {
        this.imgSize = sizeInMegas.toFixed(2);
        this.imageSizeAlert = true;
      } else {
        this.imageSizeAlert = false;
      }
    },

    async uploadImage() {
      if (!this.imageUrlByType) return;

      if (this.radioGroup == "link") {
        await this.checkPhotoLink();
        if (this.photoLinkError) {
          return;
        }

        this.$emit("update", this.imageUrlByType);
        this.closeImageUploadDialog();
        return;
      }

      this.uploadingImageLoading = true;
      const formData = new FormData();
      formData.append("image", this.file);
      formData.append("image_field_key", "sign_photo");

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/${this.user["key"]}/upload-image`;

      this.$axios
        .post(url, formData, this.auth)
        .then(({ data }) => {
          this.$emit("update", data.shared_drive_url);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          this.setSnackBar({
            message: errorMessages["FAILED_TO_UPLOAD_IMAGE"],
            color: "error",
            show: true,
          });
        })
        .finally(() => {
          this.uploadingImageLoading = false;
          this.closeImageUploadDialog();
        });
    },
  },

  computed: {
    ...mapGetters(["hasAcceptedDriveScopes", "isCompanyMicrosoft"]),

    imageUrlByType() {
      return this.imageURLPreview[this.radioGroup];
    },

    getPreviewTagImage() {
      return this.selectedTagImageURL || require("@/assets/user-default.png");
    },

    auth() {
      if (this.userToken) {
        return {
          headers: {
            Authorization: this.userToken,
          },
        };
      }
      return {
        headers: {
          Authorization: this.token,
        },
      };
    },
  },
};
</script>
